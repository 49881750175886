import { render } from "react-dom";
import ReactGA from "react-ga";
import ApolloProvider from "./ApolloProvider";
import "./index.css";

const trackingId = "UA-216710049-3"; // Replace with your Google Analytics tracking ID
ReactGA.initialize(trackingId);

// const trackingId = "G-298874242"; // Replace with your Google Analytics tracking ID
// ReactGA.initialize(trackingId, {
//   testMode: process.env.NODE_ENV === "production" ? false : true,
// });

// ReactGA.set({
//   userId: auth.currentUserId(),
//   // any data that is relevant to the user session
//   // that you'd like to track with google analytics
// });

render(ApolloProvider, document.getElementById("root"));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
// serviceWorker.unregister();
